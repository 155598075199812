<template>
  <div style="height: 100%; width: 100%; background: linear-gradient(to bottom,  #fc5c7d, #6a82fb)" v-if="PCDevice">
    <div>
      <el-row>
        <el-col :span="12">
          <el-row style="padding-top: 200px">
            <el-col :span="2">
              <el-image style="width: 170px; height: 170px; margin-left: 30px" :src="require('../assets/img/Background/XinZhaLogo.png')"></el-image>
            </el-col>
            <el-col :span="22">
              <div style="padding-left: 145px; padding-top: 60px; font-size: 50px; color: #ffffff; text-align: center; display: inline-block">心札</div>
              <p style="padding-top: 40px; margin-left: -25px; font-size: 30px; color: #ffffff;">以心度量五湖四海 以札书写喜乐平生</p>
              <el-button type="primary" style="margin-top: 20px" @click="OpenPCURL()">下载</el-button>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-image style="height: 750px" fit="cover" :src="require('../assets/img/Background/XinZhaIL.png')"></el-image>
        </el-col>
      </el-row>
    </div>
  </div>
  <div style="background: linear-gradient(to bottom,  #fc5c7d, #6a82fb)" v-if="MobileDevice">
    <div>
      <el-row>
        <el-col :span="24">
          <div style="text-align: center; padding-top: 40px">
            <el-image style="width: 170px; height: 170px; margin: 0 auto;" :src="require('../assets/img/Background/XinZhaLogo.png')"></el-image>
          </div>
          <div style="text-align: center">
            <div style="padding-top: 30px; font-size: 50px; color: #ffffff; text-align: center; display: inline-block">心札</div>
            <p style="padding-top: 30px;text-align: center; font-size: 30px; color: #ffffff;">以心度量五湖四海</p>
            <p style="padding-top: 10px;text-align: center; font-size: 30px; color: #ffffff;">以札书写喜乐平生</p>
            <el-button type="primary" style="margin-top: 20px" @click="OpenMobileURL()">下载</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-image style="text-align: center" fit="cover" :src="require('../assets/img/Background/XinZhaIL.png')"></el-image>
      </el-row>
    </div>
  </div>
  <div style="height: 750px;" v-if="PCDevice">
    <el-row>
      <el-col :span="12">
        <div style="padding-top: 40px">
          <p style="padding-top: 230px; font-size: 50px; text-align: center; padding-left: 120px; color: #27066e">暗夜模式</p>
          <p style="padding-top: 30px; font-size: 30px; padding-left: 200px; color: #27066e">昼夜兼程，畅写无阻</p>
        </div>
      </el-col>
      <el-col :span="12">
        <el-image fit="cover" style="text-align: center; margin-top: 70px; margin-left: 30px; height: 620px" :src="require('../assets/img/Background/夜间模式.png')"></el-image>
      </el-col>
    </el-row>
  </div>
  <div style="height: 750px;" v-if="MobileDevice">
    <div>
      <el-row>
        <el-col :span="24">
          <div style="padding-left: 145px; padding-top: 30px; font-size: 50px; color: #ffffff; text-align: center; display: inline-block">心札</div>
          <p style="padding-top: 30px; font-size: 40px; text-align: center; color: #27066e">暗夜模式</p>
          <p style="padding-top: 30px; font-size: 30px; text-align: center; color: #27066e">昼夜兼程，畅写无阻</p>
        </el-col>
      </el-row>
      <el-row style="padding-top: 20px">
        <el-image fit="cover" style="text-align: center" :src="require('../assets/img/Background/夜间模式.png')"></el-image>
      </el-row>
    </div>
  </div>
  <div style="height: 750px" v-if="PCDevice">
    <el-row>
      <el-col :span="12">
        <el-image fit="cover" style="height: 620px; margin-top: 70px; text-align: center" :src="require('../assets/img/Background/云端同步.png')"></el-image>
      </el-col>
      <el-col :span="12">
        <div>
          <p style="padding-top: 230px; font-size: 50px; padding-left: 50px; color: #0275dd">云端同步</p>
          <p style="padding-top: 30px; font-size: 30px; text-align: center; color: #7facdc">保障数据不丢失，让你的写作恣意纵横</p>
        </div>
      </el-col>
    </el-row>
  </div>
  <div style="height: 750px" v-if="MobileDevice">
    <div>
      <el-row>
        <el-col :span="24">
          <p style="padding-top: 50px; font-size: 40px; text-align: center; color: #0275dd">云端同步</p>
          <p style="padding-top: 30px; font-size: 30px; text-align: center; color: #7facdc">保障数据不丢失</p>
          <p style="padding-top: 30px; font-size: 30px; text-align: center; color: #7facdc">让你的写作恣意纵横</p>
        </el-col>
      </el-row>
      <el-row  style="padding-top: 20px">
        <el-image fit="cover" style="text-align: center" :src="require('../assets/img/Background/云端同步.png')"></el-image>
      </el-row>
    </div>
  </div>
  <div style="height: 750px" v-if="PCDevice">
    <el-row>
      <el-col :span="12">
        <div>
          <p style="padding-top: 230px; font-size: 50px; text-align: center; padding-left: 120px; color: #45166a">敬请期待！</p>
          <p style="padding-top: 30px; font-size: 30px; padding-left: 200px; color: #45166a">更多功能已在路途</p>
        </div>
      </el-col>
      <el-col :span="12">
        <el-image fit="cover" style="height: 620px; margin-top: 70px; text-align: center" :src="require('../assets/img/Background/敬请期待.png')"></el-image>
      </el-col>
    </el-row>
  </div>
  <div style="height: 750px" v-if="MobileDevice">
    <div>
      <el-row>
        <el-col :span="24">
          <p style="padding-top: 30px; font-size: 40px; text-align: center; color: #45166a">敬请期待！</p>
          <p style="padding-top: 30px; font-size: 30px; text-align: center; color: #45166a">更多功能已在路途</p>
        </el-col>
      </el-row>
      <el-row  style="padding-top: 20px">
        <el-image fit="cover" style="text-align: center" :src="require('../assets/img/Background/敬请期待.png')"></el-image>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XinZha',
  data () {
    return {
      MobileDevice: false,
      PCDevice: false,
      XinZhaImg1P: {
        backgroundImage: 'url(' + require('../assets/img/Background/XinZhaIL.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }
    }
  },
  methods: {
    IsPC () {
      var userAgentInfo = navigator.userAgent
      var Agents = ['Android', 'iPhone']
      var flag = true
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false
          break
        }
      }
      return flag
    },
    OpenPCURL () {
      window.open('https://shouji.baidu.com/app/5000710650')
    },
    OpenMobileURL () {
      window.open('https://mobile.baidu.com/item?docid=5000710650&source=appbaidu')
    }
  },
  mounted () {
    this.flag = this.IsPC()
    if (this.flag === false) {
      this.MobileDevice = true
      this.PCDevice = false
    } else {
      this.PCDevice = true
      this.MobileDevice = false
    }
  }
}
</script>

<style scoped>

</style>
