import { createRouter, createWebHashHistory } from 'vue-router'
import MainPage from '@/views/MainPage'
import AboutUS from '@/views/AboutUS'
import XinZha from '@/views/XinZha'
import PrivacyPolicy from '@/views/PrivacyPolicy'
import ServicesTermsAndConditions from '@/views/ServicesTermsAndConditions'
import XinZhaHelp from '@/views/XinZhaHelp'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/MainPage'
  },
  {
    path: '/MainPage',
    name: 'MainPage',
    component: MainPage
  },
  {
    path: '/AboutUS',
    name: 'AboutUS',
    component: AboutUS
  },
  {
    path: '/XinZha',
    name: 'XinZha',
    component: XinZha
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/ServicesTermsAndConditions',
    name: 'ServicesTermsAndConditions',
    component: ServicesTermsAndConditions
  },
  {
    path: '/XinZhaHelp',
    name: 'XinZhaHelp',
    component: XinZhaHelp
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
