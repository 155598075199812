<template>
  <el-affix :offset="0">
    <el-row :gutter="0" style="padding: 0; margin: 0; background-color: #ffffff; border-bottom: 1px #e6e6e7 solid">
      <el-col :span="6" v-if="PCDevice">
        <div style="height: 61px; float: left;">
          <div style="text-align: center; line-height: 60px">
          <span style="font-size: 20px; display: inline-block">
            <span style="padding-left: 15px; display: inline-block">
              <span>
                <img src="../assets/img/TingLogo.png" style="width: 40px; height: 40px; vertical-align: middle" alt="Logo">
              </span>
              <span style="font-size: 30px; font-family: sans-serif; padding-left: 4px; vertical-align: middle">
                烃其自在
              </span>
            </span>
          </span>
          </div>
        </div>
      </el-col>
      <el-col :span="18" v-if="MobileDevice">
        <div style="height: 61px; line-height: 60px; float: left;">
          <div style="text-align: center; line-height: 60px">
          <span style="font-size: 20px; display: inline-block">
            <span style="padding-left: 15px; display: inline-block">
              <span>
                <img src="../assets/img/TingLogo.png" style="width: 40px; height: 40px; vertical-align: middle" alt="Logo">
              </span>
              <span style="font-size: 30px; font-family: sans-serif; padding-left: 4px; vertical-align: middle">
                烃其自在
              </span>
            </span>
          </span>
          </div>
        </div>
      </el-col>
      <el-col :span="12" v-if="PCDevice">
        <div style="display: flex; justify-content: center">
          <el-menu :default-active="ActiveIndex" class="el-menu-demo" mode="horizontal"
                   text-color="#888c92" active-text-color="black" :router="true"
                   style="padding: 0; margin: 0 auto; text-align: center" v-show="PCDevice">
            <el-menu-item index="0" :route="{name: 'MainPage'}" style="font-size:20px">首页</el-menu-item>
            <el-menu-item index="1" :route="{name: 'XinZha'}" style="font-size:20px">心札</el-menu-item>
            <el-menu-item index="2" :route="{name: 'AboutUS'}" style="font-size:20px">关于我们</el-menu-item>
<!--            <el-menu-item index="3" style="font-size:20px">新闻</el-menu-item>-->
          </el-menu>
        </div>
      </el-col>
      <el-col :span="6" v-if="PCDevice">
        <div style="height: 61px; display: flex">
        </div>
      </el-col>
      <el-col :span="6" v-if="MobileDevice" style="float: right">
<!--        <el-menu :default-active="ActiveIndex" class="el-menu-demo" mode="horizontal" :router="true"-->
<!--                 menu-trigger="click" v-show="MobileDevice" style="float: right">-->
<!--          <el-submenu index="1" style="float: right" popper-offset="0">-->
<!--            <template #title>菜单</template>-->
<!--            <el-menu-item index="0" :route="{ name: 'MainPage' }" style="font-size:20px"><span style="font-family: STFangsong">首页</span></el-menu-item>-->
<!--            <el-menu-item index="1" :route="{ name: 'XinZha' }" style="font-size:20px"><span style="font-family: STFangsong">心札</span></el-menu-item>-->
<!--            <el-menu-item index="2" :route="{ name: 'AboutUS' }" style="font-size:20px"><span style="font-family: STFangsong">关于我们</span></el-menu-item>-->
<!--          </el-submenu>-->
<!--        </el-menu>-->
        <el-row>
          <el-col :span="24">
            <div style="float:right; padding-right: 15px; padding-top: 17px">
              <div @click="drawer = true" style="margin-left: 16px; font-size: 20px">
                菜单
              </div>
            </div>
          </el-col>
        </el-row>
        <el-drawer v-model="drawer" direction="ttb" size="260px">
          <div style="text-align:center; height: 700px">
            <div style="font-size: 20px;" @click="JumpMainPage()" v-show="CurrentIndexP == 1">
              首页
            </div>
            <div style="font-size: 20px; color: #868688" @click="JumpMainPage()" v-show="CurrentIndexP !== 1">
              首页
            </div>
            <div style="font-size: 20px; padding-top: 20px" @click="JumpXinZha()" v-show="CurrentIndexP == 2">
              心札
            </div>
            <div style="font-size: 20px; padding-top: 20px; color: #868688" @click="JumpXinZha()" v-show="CurrentIndexP !== 2">
              心札
            </div>
            <div style="font-size: 20px; padding-top: 20px" @click="JumpAboutUS()" v-show="CurrentIndexP == 3">
              关于我们
            </div>
            <div style="font-size: 20px; padding-top: 20px; padding-bottom: 40px; color: #868688" @click="JumpAboutUS()" v-show="CurrentIndexP !== 3">
              关于我们
            </div>
          </div>
        </el-drawer>
      </el-col>
    </el-row>
  </el-affix>
</template>

<script>
export default {
  name: 'TopMenu',
  data () {
    return {
      ActiveIndex: '0',
      MobileDevice: false,
      PCDevice: false,
      drawer: false,
      CurrentIndexP: 1
    }
  },
  methods: {
    IsPC () {
      const userAgentInfo = navigator.userAgent
      const Agents = ['Android', 'iPhone']
      let flag = true
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false
          break
        }
      }
      return flag
    },
    JumpMainPage () {
      this.$router.push({ name: 'MainPage' })
      this.CurrentIndexP = 1
      this.drawer = false
    },
    JumpXinZha () {
      this.$router.push({ name: 'XinZha' })
      this.CurrentIndexP = 2
      this.drawer = false
    },
    JumpAboutUS () {
      this.$router.push({ name: 'AboutUS' })
      this.CurrentIndexP = 3
      this.drawer = false
    }
  },
  mounted () {
    this.flag = this.IsPC()
    if (this.flag === false) {
      this.MobileDevice = true
      this.PCDevice = false
    } else {
      this.PCDevice = true
      this.MobileDevice = false
    }
  }
}
</script>

<style>
.el-menu--horizontal > .el-menu-item {
  border-bottom: none !important;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none !important;
}
.el-submenu.is-active .el-submenu__title {
  border-bottom: none !important;
}
::v-deep .el-submenu.is-active .el-submenu__title {
  border-bottom: none !important;
}
</style>
