<template>
  <el-container>
    <el-main>
      <el-row class="AboutUSText" style="height: 800px">
        <el-col :span="24">
          <el-link href="mailto:fireting2021@126.com" :underline="false">
            <p style="font-size: 23px">联系我们：fireting2021@126.com</p>
          </el-link>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'AboutUS'
}
</script>

<style scoped>

</style>
