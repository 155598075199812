<template>
  <TopMenu/>
  <router-view></router-view>
  <div style="text-align: center; color: #60646D; font-size: 14px; padding-top: 10px" >
    <el-link href="https://fireting.com/#/PrivacyPolicy/" :underline="false">
      隐私政策
    </el-link>
  </div>
  <div style="text-align: center; color: #60646D; font-size: 14px; padding-top: 10px" >
    <el-link href="https://fireting.com/#/ServicesTermsAndConditions" :underline="false">
      用户协议
    </el-link>
  </div>
  <div style="text-align: center; color: #60646D; font-weight: 400; font-size: 16px; padding-top: 10px">
    Copyright©烃其自在（北京）科技有限公司
  </div>
  <div style="text-align: center">
    <el-link href="https://beian.miit.gov.cn/" :underline="false">
      <p style="font-size: 14px; font-weight: 400;">京ICP备2022003302号</p>
    </el-link>
  </div>
  <div style="text-align: center; padding-bottom: 20px">
    <el-link href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010602104077" :underline="false">
      <p style="font-size: 14px; font-weight: 400;">京公网安备11010602104077号</p>
    </el-link>
  </div>
</template>

<script>

import TopMenu from '@/components/TopMenu'
export default {
  name: 'App',
  components: { TopMenu },
  methods: {
    JumpPrivacyPolicy () {
      this.$router.push({ name: 'PrivacyPolicy' })
    },
    JumpServicesTermsAndConditions () {
      this.$router.push({ name: 'ServicesTermsAndConditions' })
    }
  }
}
</script>

<style>
</style>
