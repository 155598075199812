<template>
  <div v-if="PCDevice">
    <el-container :style="Img1">
      <el-main>
        <el-row>
          <el-col :span="24">
            <div style="height: 750px; width: 100%;">
              <p class="MainPageCompanyName">烃其自在</p>
              <p class="FireTing">FireTing</p>
              <p class="MainPageText">我们致力于工具App的开发并专注于提供更好的体验</p>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
  <div v-if="MobileDevice">
    <el-container :style="Img1P" height="100%">
      <el-main>
        <el-row>
          <el-col :span="24">
            <div style="height: 750px; width: 100%;">
              <p style="font-size: 50px; padding-top: 80px; text-align: center; color: aliceblue;">烃其自在</p>
              <p class="FireTing">FireTing</p>
              <p class="MainPageText">我们致力于工具App的开发并专注于提供更好的体验</p>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
  <div v-if="PCDevice">
    <el-container :style="Img3" height="100%">
      <el-main>
        <el-row>
          <div style="height: 750px; width: 100%;">
            <el-row>
              <el-col :span="10">
                <div style="height: 100%">
                  <p style="padding-top: 270px; padding-left: 50px; font-size: 40px; text-align: center; color: aliceblue;">永远热爱幻想</p>
                  <p style="padding-top: 10px; padding-left: 150px; font-size: 40px; text-align: center; color: aliceblue;">永远不惧寂寞</p>
                </div>
              </el-col>
              <el-col :span="14"></el-col>
            </el-row>
          </div>
        </el-row>
      </el-main>
    </el-container>
  </div>
  <div v-if="MobileDevice">
    <el-container :style="Img3P" height="100%">
      <el-main>
        <el-row>
          <div style="height: 750px; width: 100%;">
            <el-row>
              <el-col :span="24">
                <div style="min-height: 100%">
                  <p style="padding-top: 250px; font-size: 35px; text-align: center; color: aliceblue;">永远热爱幻想</p>
                  <p style="padding-top: 10px; font-size: 35px; text-align: center; color: aliceblue; padding-left: 100px">永远不惧寂寞</p>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-row>
      </el-main>
    </el-container>
  </div>
  <div v-if="PCDevice">
    <el-container :style="Img4">
      <el-main>
        <el-row>
          <div style="height: 750px; width: 100%;">
            <el-row>
              <el-col :span="12">
                <div style="height: 100%; width: 100%;">
                  <p style="padding-top: 100px; padding-left: 50px; font-size: 40px; color: #7ea4d0; margin-bottom: 0"> 未来已来</p>
                  <p style="padding-top: 20px; padding-left: 90px; color: #7ea4d0; font-size: 30px; text-align: center;">与烃其自在一起,见证科技与幻想的交汇</p>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-row>
      </el-main>
    </el-container>
  </div>
  <div v-if="MobileDevice">
    <el-container :style="Img4P" height="100%">
      <el-main>
        <el-row>
          <div style="height: 720px">
            <el-row>
              <el-col :span="24">
                <div style="min-height:720px">
                  <p style="padding-top: 100px; padding-left: 30px;font-size: 40px; color: #7ea4d0; margin-bottom: 0"> 未来已来</p>
                  <p style="padding-top: 30px; padding-left: 70px;font-size: 23px; text-align: center; color: #7ea4d0;">与烃其自在一起见证科技与幻想的交汇</p>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-row>
      </el-main>
    </el-container>
  </div>
  <div style="padding-top: 20px;" v-if="PCDevice">
    <!--      <el-row>-->
    <!--        <el-col :span="11"></el-col>-->
    <!--        <el-col :span="3">-->
    <!--          <el-image style="width: 40px; height: 40px" :src="require('../assets/logo/Weibo.png')" @click="OpenURL('https://weibo.com/u/7744361862')"></el-image>-->
    <!--          <el-popover placement="top" :width="230" trigger="hover">-->
    <!--            <template #reference>-->
    <!--              <el-image style="width: 40px; height: 40px; margin-left: 10px;" :src="require('../assets/logo/WeChat.png')"></el-image>-->
    <!--            </template>-->
    <!--            <el-image style="width: 200px; height: 200px;" :src="require('../assets/img/微信公众号.jpg')"></el-image>-->
    <!--          </el-popover>-->
    <!--          &lt;!&ndash;<el-image style="width: 40px; height: 40px; margin-left: 10px;" :src="require('../assets/logo/ZhiHu.png')" @click="OpenURL('https:\\www.bilibili.com')"></el-image>&ndash;&gt;-->
    <!--          &lt;!&ndash;<el-image style="width: 40px; height: 40px; margin-left: 10px" :src="require('../assets/logo/BilibiliLogo.png')" @click="OpenURL('https:\\www.bilibili.com')"></el-image>&ndash;&gt;-->
    <!--          <el-image style="width: 40px; height: 40px; margin-left: 10px" :src="require('../assets/logo/ByteDance.png')" @click="OpenURL('https://profile.zjurl.cn/rogue/ugc/profile/?user_id=2173112378196766&media_id=1727019307928580&request_source=1')"></el-image>-->
    <!--        </el-col>-->
    <!--        <el-col :span="10"></el-col>-->
    <!--      </el-row>-->
    <el-row>
      <el-col :span="24">
        <div style="text-align: center;">
          <div>
            <el-image style="width: 40px; height: 40px" :src="require('../assets/logo/Weibo.png')" @click="OpenURL('https://weibo.com/u/7744361862')"></el-image>
            <el-popover placement="top" :width="230" trigger="hover">
              <template #reference>
                <el-image style="width: 40px; height: 40px; margin-left: 10px;" :src="require('../assets/logo/WeChat.png')"></el-image>
              </template>
              <el-image style="width: 200px; height: 200px;" :src="require('../assets/img/微信公众号.jpg')"></el-image>
            </el-popover>
            <!--<el-image style="width: 40px; height: 40px; margin-left: 10px;" :src="require('../assets/logo/ZhiHu.png')" @click="OpenURL('https:\\www.bilibili.com')"></el-image>-->
            <!--<el-image style="width: 40px; height: 40px; margin-left: 10px" :src="require('../assets/logo/BilibiliLogo.png')" @click="OpenURL('https:\\www.bilibili.com')"></el-image>-->
            <el-image style="width: 40px; height: 40px; margin-left: 10px" :src="require('../assets/logo/ByteDance.png')" @click="OpenURL('https://profile.zjurl.cn/rogue/ugc/profile/?user_id=2173112378196766&media_id=1727019307928580&request_source=1')"></el-image>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  <div style="padding-top: 20px;" v-if="MobileDevice">
    <el-row>
      <el-col :span="24">
        <div style="text-align: center;">
          <div>
            <el-image style="width: 25px; height: 25px" :src="require('../assets/logo/Weibo.png')" @click="OpenURL('https://weibo.com/u/7744361862')"></el-image>
            <el-popover placement="top" :width="230" trigger="hover">
              <template #reference>
                <el-image style="width: 25px; height: 25px; margin-left: 10px;" :src="require('../assets/logo/WeChat.png')"></el-image>
              </template>
              <el-image style="width: 200px; height: 200px;" :src="require('../assets/img/微信公众号.jpg')"></el-image>
            </el-popover>
            <!--<el-image style="width: 40px; height: 40px; margin-left: 10px;" :src="require('../assets/logo/ZhiHu.png')" @click="OpenURL('https:\\www.bilibili.com')"></el-image>-->
            <!--<el-image style="width: 40px; height: 40px; margin-left: 10px" :src="require('../assets/logo/BilibiliLogo.png')" @click="OpenURL('https:\\www.bilibili.com')"></el-image>-->
            <el-image style="width: 25px; height: 25px; margin-left: 10px" :src="require('../assets/logo/ByteDance.png')" @click="OpenURL('https://profile.zjurl.cn/rogue/ugc/profile/?user_id=2173112378196766&media_id=1727019307928580&request_source=1')"></el-image>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'CompanyMainPage',
  data () {
    return {
      MobileDeviceImage: false,
      PCDeviceImage: false,
      Img1: {
        backgroundImage: 'url(' + require('../assets/img/Background/Main2.jpg') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      },
      Img1P: {
        backgroundImage: 'url(' + require('../assets/img/Background/Main2P.jpg') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      },
      Img3: {
        backgroundImage: 'url(' + require('../assets/img/Background/BackImage2.jpg') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      },
      Img3P: {
        backgroundImage: 'url(' + require('../assets/img/Background/BackImage2P.jpg') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      },
      Img4: {
        backgroundImage: 'url(' + require('../assets/img/Background/BackImage3.jpg') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      },
      Img4P: {
        backgroundImage: 'url(' + require('../assets/img/Background/BackImage3P.jpg') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      },
      MobileDevice: false,
      PCDevice: false
    }
  },
  methods: {
    IsPC () {
      var userAgentInfo = navigator.userAgent
      var Agents = ['Android', 'iPhone']
      var flag = true
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false
          break
        }
      }
      return flag
    },
    OpenURL (url) {
      // window.open(url)
      window.open(url, '_blank')
    }
  },
  mounted () {
    this.flag = this.IsPC()
    if (this.flag === false) {
      this.MobileDevice = true
      this.PCDevice = false
    } else {
      var ua = navigator.userAgent.toLowerCase()
      if (/ipad|tablet|(android(?!.*mobile))/.test(ua)) {
        this.MobileDevice = true
        this.PCDevice = false
      } else {
        this.PCDevice = true
        this.MobileDevice = false
      }
    }
  }
}
</script>

<style scoped>
.container{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>
