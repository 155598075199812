import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import installElementPlus from './plugins/element'
import '@/assets/css/MainPageCompanyName.css'
import '@/assets/css/MainPageText.css'
import '@/assets/css/AboutUSText.css'
import '@/assets/css/FireTing.css'
import '@/assets/css/global.css'
import '@/assets/css/MainPage.css'

const app = createApp(App)
installElementPlus(app)
app.use(router).mount('#app')
